<template>
    <div class="registration">
        <div class="registration__heading">
            <h1>Вхід</h1>
        </div>

        <div class="registration__content">
            <form action id="pills-home" @submit.prevent="submitHandler" autocomplete="off">
                <div class="registration__form-group">
                    <input autocomplete="off" v-model.trim="email" type="text" placeholder="Електронна адреса" :class="{invalid: ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email) || errorLogin}" />
                    <small class="error" v-if="$v.email.$dirty && !$v.email.required">Поле e-mail не повинно бути порожнім</small>
                    <small class="error" v-else-if="$v.email.$dirty && !$v.email.email">Введіть коректний email</small>
                </div>
                <div class="registration__form-group">
                    <span :class="['showPassword', {active: typePassword === 'text'}]" @click="changeType">
                        <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.0832 7.39531C18.8613 2.71484 15.5027 0.359375 11.0004 0.359375C6.49569 0.359375 3.13944 2.71484 0.917567 7.39766C0.828447 7.58637 0.782227 7.79247 0.782227 8.00117C0.782227 8.20987 0.828447 8.41598 0.917567 8.60469C3.13944 13.2852 6.49804 15.6406 11.0004 15.6406C15.5051 15.6406 18.8613 13.2852 21.0832 8.60234C21.2637 8.22266 21.2637 7.78203 21.0832 7.39531ZM11.0004 13.9531C7.21991 13.9531 4.45194 12.0359 2.4996 8C4.45194 3.96406 7.21991 2.04688 11.0004 2.04688C14.7808 2.04688 17.5488 3.96406 19.5012 8C17.5512 12.0359 14.7832 13.9531 11.0004 13.9531ZM10.9066 3.875C8.6285 3.875 6.78163 5.72188 6.78163 8C6.78163 10.2781 8.6285 12.125 10.9066 12.125C13.1848 12.125 15.0316 10.2781 15.0316 8C15.0316 5.72188 13.1848 3.875 10.9066 3.875ZM10.9066 10.625C9.45585 10.625 8.28163 9.45078 8.28163 8C8.28163 6.54922 9.45585 5.375 10.9066 5.375C12.3574 5.375 13.5316 6.54922 13.5316 8C13.5316 9.45078 12.3574 10.625 10.9066 10.625Z" fill="#C2D2E3"/>
                        </svg>
                    </span>
                    <input autocomplete="off" v-model.trim="password" :type="typePassword" placeholder="Введіть пароль" :class="{invalid: ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength) || errorLogin}"/>
                    <small class="error" v-if="$v.password.$dirty && !$v.password.required">Введіть пароль</small>
                    <small class="error" v-else-if="$v.password.$dirty && !$v.password.minLength">Пароль повинен містити мінімум {{$v.password.$params.minLength.min}} символів. Зараз він містить {{password.length}} символів</small>
                </div>
                <div class="registration-error" v-if="errorLogin">Ви ввели неправильні дані </div>
                <router-link :to="{ name: 'forgot-password'}">
                    Вiдновлення пароля
                </router-link>
                <button type="submit" class="submit">Далі</button>
            </form>
            <div class="registration__descr">
                <span>Немає облікового запису?</span>
                <router-link to="/registration"> Зареєструватися</router-link>
            </div>
            <!--<router-link to="/profile">Профиль</router-link>-->
        </div>
    </div>
</template>

<script>
import {email, required, minLength} from 'vuelidate/lib/validators'
export default {
    name: 'login',
    data: () => ({
        email: '',
        password: '',
        typePassword: 'password',
        errorLogin: false,
    }),

    validations: {
        email: {email, required},
        password: {required, minLength: minLength(6)}
    },

    methods:{
        submitHandler() {
            if(this.$v.$invalid){
                this.$v.$touch()
                return
            }
            let email = this.email
            let password = this.password
            this.$store.dispatch('LOGIN', { email, password })
            // .then(() => this.$router.replace({name: 'history'}))
            .then(() => {
                //TODO костыиль, а вообще нужно найти как в vuejs  обновить урл с перезагрузкой
                window.location.href = '/prime';
                })
            .catch(err => (console.log(err), this.errorLogin = true))
        },

        changeType () {
            if (this.typePassword === 'password') {
                this.typePassword = 'text'
            } else {
                this.typePassword = 'password'
            }
        }
    },

}
</script>

